import React from 'react'
import {Stack, Typography} from '@mui/material'

type imageTextStackedType = {
    svgComponent: React.ComponentType
    header: string
    body: string

}
export const ImageTextStacked: React.FC<imageTextStackedType> = (props: imageTextStackedType) => {
    const Svg = props.svgComponent
    return (
        <Stack direction='column' sx={{ml: '3.5vw', mr:'1vw'}}>
            <Svg/>
            <Typography align='left' variant='h2' width='168px' fontFamily="Inter" height='32px' sx={{pt:'30px', fontWeight: '600px', fontSize: '24px', lineHeight: '32px'}}>
                {props.header}
            </Typography>
            <Typography align='left' variant="caption" width='168px'fontFamily="Inter"  height='110px' sx={{pt: '30px', fontWeight:'400px', fontSize: '14px', lineHeight:'22px'}}>
                {props.body}
            </Typography>
        </Stack>
    )
}