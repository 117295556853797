import React, { useState } from "react";
import { Typography, Stack, Button } from "@mui/material";
import { mouseOff, mouseOn } from "./blurEffect";

type titleGroupProps = {
  header: string;
  svg: any;
};

export const TitleGroup: React.FC<titleGroupProps> = (props) => {
  const SVG = props.svg;
  return (
    <Stack width="648px" height="247px" sx={{ ml: "81px", mt: "217px" }}>
      <Stack direction="column">
        <Typography
          variant="h3"
          align="left"
          fontFamily="Inter"
          fontSize={72}
          onMouseEnter={(e) => mouseOn(e)}
          onMouseLeave={(e) => mouseOff(e)}
        >
          {props.header}
        </Typography>
      </Stack>
      <Stack direction="column" alignContent="left" sx={{ mt: "50px" }}>
        <SVG />
      </Stack>
    </Stack>
  );
};
