import { Divider, Stack } from "@mui/material";
import { Description } from "src/components/reusable/description";
import { TextWithDiagram } from "./textWithDiagram";

export const Section3: React.FC = () => {
  return (
    <>
    <TextWithDiagram />
    <Stack sx={{pt: '50vh', ml: '80px', mr: '80px'}}>
            <Divider sx={{borderColor: '#FFFFFF', border: '1px', borderStyle: 'solid'}}/>
        </Stack>
    </>
  );
};
