import "./App.css";
import { Home } from "./pages/home";
import { Solutions } from "./pages/solutions";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useState } from "react";

export default function App() {
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home navOpen={navOpen} setNavOpen={setNavOpen} />,
    },
    {
      path: "/home",
      element: <Home navOpen={navOpen} setNavOpen={setNavOpen} />,
    },
    {
      path: "/solutions",
      element: <Solutions navOpen={navOpen} setNavOpen={setNavOpen} />,
    },
  ]);
  return <RouterProvider router={router} />;
}
