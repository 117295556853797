import React from "react";
import { Divider, Stack, Box, Typography } from "@mui/material";
import { ReactComponent as Svg1 } from "src/resources/infinity.svg";
import { ReactComponent as Svg2 } from "src/resources/aiSolution.svg";
export const Section2: React.FC = () => {
  const Infinity: React.ElementType = () => {
    return <Svg1 />;
  };
  const AiSolution: React.ElementType = () => {
    return <Svg2 />;
  };
  return (
    <>
      <Stack sx={{ pt: "150px", ml: "80px", mr: "80px" }}>
        <Divider
          sx={{ borderColor: "#FFFFFF", border: "1px", borderStyle: "solid" }}
        />
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{ mt: "100px" }}
        >
          <Stack
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={4}
          >
            <Box>
              <Infinity />
            </Box>
            <Box sx={{ mt: "3.5vh" }}>
              <AiSolution />
            </Box>
            <Box sx={{ mt: "3.5vh" }}>
              <Typography
                fontFamily="Inter"
                fontWeight="400px"
                fontSize="50px"
                sx={{ lineHeight: "60px" }}
              >
                AI
              </Typography>
            </Box>
            <Box sx={{ mt: "3.5vh"}}>
              <Typography
                fontFamily="Inter"
                fontWeight="400px"
                fontSize="16px"
                sx={{ lineHeight: "24px" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt
              </Typography>
            </Box>
            <Box width="15vw" height="25vh" sx={{ mt: "3.5vh", border: "1px solid #FFDC3B"}}>
            <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography fontFamily="Inter"
                fontWeight="400px"
                fontSize="50px"
                sx={{mt: '2vh', lineHeight: '60px' }}>
                98%
            </Typography>
            <Typography fontFamily="IBM Plex Mono"
                fontWeight="400px"
                fontSize="12px"
                sx={{mt: '2vh', lineHeight: '20px' }}>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt            </Typography>
            </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
