import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import { Description } from "src/components/reusable/description";

export const TextWithDiagram: React.FC = () => {
  const Component: React.ElementType = () => {
    return (
      <Box
        width="100px"
        height="100px"
        sx={{
          backgroundColor: "#BCFF2D",
        }}
      ></Box>
    );
  };
  return (
    <Stack direction="row" justifyContent="space-between">
      <Description
        showDemoButton={false}
        showHeaderText={true}
        header={"What is our mission?"}
        body={
          "We are a software development company that specializes in creating web applications, AI applications, and other custom software solutions to meet the needs of our clients. Our mission is to provide top-notch products and services to our customers at an affordable cost, while always striving for excellence and innovation. We believe that through our dedication to quality, customer service, and technological advancement, we will create a lasting legacy of success for our client"
        }
        sx={{ width: "507px" }}
        additionalComponent={Component}
      />
    </Stack>
  );
};
