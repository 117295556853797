import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
export const ColorButton = styled(Button)(() => ({
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    ':hover': {
      color: "#BCFF2D",
      filter: "blur(4px)",
      backgroundColor: "#FFFFFF",
      borderColor: "#FFFFFF",
    },
  }));