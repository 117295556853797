import { Stack, Divider } from '@mui/material'
import React from 'react'
import { ImageTextStacked } from './imgTextStack'
import {ReactComponent as CodeSvg} from './code.svg'
export const CodeFlow: React.FC = () => {
    return (
        <>
        <Stack direction='row' sx={{pt: '250px'}}>
            <ImageTextStacked svgComponent={CodeSvg} header={"Code"} body={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"}/>
            <ImageTextStacked svgComponent={CodeSvg} header={"Control"} body={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"}/>
            <ImageTextStacked svgComponent={CodeSvg} header={"Cloud"} body={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"}/>
            <ImageTextStacked svgComponent={CodeSvg} header={"Screen"} body={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"}/>
            <ImageTextStacked svgComponent={CodeSvg} header={"Support"} body={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"}/>
        </Stack>
        <Stack sx={{pt: '150px', ml: '80px', mr: '80px'}}>
            <Divider sx={{borderColor: '#FFFFFF', border: '1px', borderStyle: 'solid'}}/>
        </Stack>
        </>
    )
}