import { Header } from "src/components/reusable/header";
import { TitleGroup } from "../../../components/reusable/titleGroup";
import { ReactComponent as Button } from "../../../resources/homeButton.svg";
import { OverlayProps } from "src/types";
import { VectorWave } from "./vectorWave";
import { Stack } from "@mui/system";

export const Section1: React.FC<OverlayProps> = (props) => {
  const { navOpen, setNavOpen } = props;

  return (
    <>
      <Header navOpen={navOpen} setNavOpen={setNavOpen} />
      <Stack direction="row" justifyContent ="space-between" alignContent="center">
      <TitleGroup
        header="A cloud design &
                architecture group"
        svg={Button}
      />
      <VectorWave />
      </Stack>
    </>
  );
};
