import { Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ReactComponent as WantaDemo } from "../../resources/wantToDemo.svg";
import { ReactComponent as DemoButton } from "../../resources/homeButton.svg";
import { ReactComponent as Logo } from "src/resources/Logo.svg";
import { mouseOff, mouseOn } from "./blurEffect";

export const Footer: React.FC = () => {
  return (
    <Stack sx={{ ml: "80px", mr: "80px" }}>
      <Divider
        sx={{ borderColor: "#FFFFFF", border: "1px", borderStyle: "solid" }}
      />
      <Stack direction="row" sx={{ mt: "10vh", mb: "10vh" }}>
        <WantaDemo />
        <DemoButton style={{ marginLeft: "50%" }}>Request a Demo</DemoButton>
      </Stack>
      <Divider
        sx={{ borderColor: "#FFFFFF", border: "1px", borderStyle: "solid" }}
      />
      <Stack id="Logo" direction="row" sx={{ mt: "5vh", ml: "-1.5vw" }}>
        <Logo width="219px" height="32.17px" />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mr: "10vw" }}
      >
        <Stack direction="column" sx={{mt:"-8.5vh"}}>
          <Typography
            fontFamily="IBM Plex Sans"
            fontWeight="700px"
            fontSize="16px"
            onMouseEnter={(e) => mouseOn(e)}
            onMouseLeave={(e) => mouseOff(e)}
            sx={{ lineHeight: "20px", pb: "2vh"}}
          >
            Home
          </Typography>
          <Typography
            fontFamily="IBM Plex Sans"
            fontWeight="700px"
            fontSize="16px"
            onMouseEnter={(e) => mouseOn(e)}
            onMouseLeave={(e) => mouseOff(e)}
            sx={{ lineHeight: "20px" }}
          >
            Contact
          </Typography>
        </Stack>
        <Stack direction="column" sx={{pl: '2vw'}}>
          <Typography
            fontFamily="IBM Plex Sans"
            fontWeight="700px"
            fontSize="16px"
            onMouseEnter={(e) => mouseOn(e)}
            onMouseLeave={(e) => mouseOff(e)}
            sx={{ lineHeight: "20px", pb: "2vh" }}
          >
            Solutions
          </Typography>
          <Typography
            fontFamily="IBM Plex Sans"
            fontWeight="700px"
            fontSize="16px"
            onMouseEnter={(e) => mouseOn(e)}
            onMouseLeave={(e) => mouseOff(e)}
            sx={{ lineHeight: "20px",  pb: "2vh" }}
          >
            AI
          </Typography>
          <Typography
            fontFamily="IBM Plex Sans"
            fontWeight="700px"
            fontSize="16px"
            onMouseEnter={(e) => mouseOn(e)}
            onMouseLeave={(e) => mouseOff(e)}
            sx={{ lineHeight: "20px",  pb: "2vh" }}
          >
            Web Apps
          </Typography>
          <Typography
            fontFamily="IBM Plex Sans"
            fontWeight="700px"
            fontSize="16px"
            onMouseEnter={(e) => mouseOn(e)}
            onMouseLeave={(e) => mouseOff(e)}
            sx={{ lineHeight: "20px" }}
          >
            Software
          </Typography>
        </Stack>
      </Stack>
      <Stack id="Rights" sx={{ mt: "20vh" }}>
        <Typography>© 2023 Wenntech All Rights Reserved.</Typography>
      </Stack>
    </Stack>
  );
};
