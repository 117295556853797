import React from "react";
import { Stack, Typography } from "@mui/material";
import { OverlayProps } from "src/types";
import { ReactComponent as PartialLogo } from "../../resources/partialLogo.svg";
import { ColorButton } from "./coloredButton";
import { mouseOff, mouseOn } from "./blurEffect";


import useWindowSize from "@rooks/use-window-size";

//https://nextparticle.nextco.de/


import ParticleImage, {
  ParticleOptions,
  Vector,
  forces,
  ParticleForce
} from "react-particle-image";

// Round number up to nearest step for better canvas performance
const round = (n: number, step = 20) => Math.ceil(n / step) * step;

// Try making me lower to see how performance degrades
const STEP = 30;

const particleOptions: ParticleOptions = {
  filter: ({ x, y, image }) => {
    // Get pixel
    const pixel = image.get(x, y);
    // Make a particle for this pixel if magnitude < 200 (range 0-255)
    const magnitude = (pixel.r + pixel.g + pixel.b) / 3;
    return magnitude < 200;
  },
  color: ({ x, y, image }) => {
    const pixel = image.get(x, y);
    // Canvases are much more performant when painting as few colors as possible.
    // Use color of pixel as color for particle however round to nearest 30
    // to decrease the number of unique colors painted on the canvas.
    // You'll notice if we remove this rounding, the framerate will slow down a lot.
    return `rgba(
      ${round(pixel.r, STEP)}, 
      ${round(pixel.g, STEP)}, 
      ${round(pixel.b, STEP)}, 
      ${round(pixel.a, STEP) / 255}
    )`;
  },
  radius: ({ x, y, image }) => {
    const pixel = image.get(x, y);
    const magnitude = (pixel.r + pixel.g + pixel.b) / 3;
    // Lighter colors will have smaller radius
    return 3 - (magnitude / 255) * 1.5;
  },
  mass: () => 100,
  friction: () => 0.15,
  initialPosition: ({ canvasDimensions }) => {
    return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
  }
};

const motionForce = (x: number, y: number): ParticleForce => {
  return forces.disturbance(x, y, 100);
};



export const Overlay: React.FC<OverlayProps> = (props: OverlayProps) => {
  const { navOpen, setNavOpen } = props;

  const mountedStyle = {
    animation: "2500ms 1 forwards inAnimation",
  };
  const unmountedStyle = {
    animation: "2500ms 1 backwards outAnimation",
  };
  const { innerWidth, innerHeight } = useWindowSize();

  return (
    <Stack
      id="OverlayGroup"
      direction="row"
      width="100%"
      justifyContent="space-between"
      sx={navOpen ? mountedStyle : unmountedStyle}
    >
      <Stack direction="column" sx={{ mt: "134px", ml: "5vh" }}>
        <Typography
          onMouseEnter={(e) => mouseOn(e)}
          onMouseLeave={(e) => mouseOff(e)}
          align="left"
          fontFamily="Inter"
          fontSize="70px"
          sx={{ fontWeight: "600px", lineHeight: "76px", mb: "5vh" }}
        >
          Home
        </Typography>
        <Typography
          onMouseEnter={(e) => mouseOn(e)}
          onMouseLeave={(e) => mouseOff(e)}
          align="left"
          fontFamily="Inter"
          fontSize="70px"
          sx={{ fontWeight: "600px", lineHeight: "76px", mb: "5vh" }}
        >
          Work
        </Typography>
        <Typography
          onMouseEnter={(e) => mouseOn(e)}
          onMouseLeave={(e) => mouseOff(e)}
          align="left"
          fontFamily="Inter"
          fontSize="70px"
          sx={{ fontWeight: "600px", lineHeight: "76px", mb: "5vh" }}
        >
          Solutions
        </Typography>
        <Typography
          onMouseEnter={(e) => mouseOn(e)}
          onMouseLeave={(e) => mouseOff(e)}
          align="left"
          fontFamily="Inter"
          fontSize="70px"
          sx={{ fontWeight: "600px", lineHeight: "76px", mb: "5vh" }}
        >
          Contact
        </Typography>
      </Stack>
      <Stack id="Close" direction="column" sx={{ mt: "50px", mr: "80px" }}>
        <ColorButton
          onClick={(_) => setNavOpen(false)}
          color="primary" //Make White
          variant="outlined"
          sx={{
            width: "98px",
            height: "45px",
          }}
        >
          Close
        </ColorButton>
        <PartialLogo
          style={{ marginTop: "80vh" }}
          onMouseEnter={(e) => mouseOn(e)}
          onMouseLeave={(e) => mouseOff(e)}
        />
    </Stack>

    </Stack>
  );
};
