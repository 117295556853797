import React from "react";
import { ReactComponent as Logo } from "src/resources/Logo.svg";
import { Stack } from "@mui/material";
import { OverlayProps } from "src/types";
import { ColorButton } from "./coloredButton";
import { mouseOff, mouseOn } from "./blurEffect";

export const Header: React.FC<OverlayProps> = (props) => {
  const { navOpen, setNavOpen } = props;
  return (
    <Stack
      id="HeaderGroup"
      direction="row"
      width="100%"
      justifyContent="space-between"
    >
      <Stack id="Logo" direction="row" sx={{ mt: "56px", ml: "81px" }}>
        <Logo
          width="219px"
          height="32.17px"
        />
      </Stack>
      <Stack
        id="Menu"
        direction="column"
        sx={{ mt: "50px", mr: "80px" }}
      >
        <Stack direction="column" sx={{ backgroundColor: "#0F0F0F" }}>
          <ColorButton
            onMouseEnter={(e) => mouseOn(e)}
            onMouseLeave={(e) => mouseOff(e)}
            onClick={(_) => setNavOpen(true)}
            color="secondary" //Make White
            variant="outlined"
            sx={{
              color: "#FFFFFF",
              width: "98px",
              height: "45px",
            }}
          >
            Menu
          </ColorButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
