import React, { useState } from "react";
import { Footer } from "src/components/reusable/footer";
import { Overlay } from "src/components/reusable/overlay";
import { Section1 } from "src/sections/home/section1";
import { Section2 } from "src/sections/home/section2";
import { Section3 } from "src/sections/home/section3";
import { Section4 } from "src/sections/home/section4";
import { OverlayProps } from "src/types";
import VectorTitlePage from "../resources/VectorTitlePage.svg";

export const Home: React.FC<OverlayProps> = (props) => {
  const { navOpen, setNavOpen } = props;
  const mainContainerStyle = {
      backgroundColor: "#0F0F0F",
      width: "100vw",
      height: "100vh",
  }  
  const mountedStyle = {
    ...mainContainerStyle,
    animation: "2500ms 1 forwards inAnimation",
  };
  const unmountedStyle = {
    ...mainContainerStyle,
    animation: "2500ms 1 backwards outAnimation",
  };
  return (
    <>
      {navOpen ? (
        <div
        id="MainContainer"
        style={{
          backgroundColor: "#0F0F0F",
          // backgroundImage: `url(${VectorTitlePage})`,
          // backgroundRepeat: "no-repeat",
          // backgroundAttachment: "fixed",
          // backgroundPosition: "right",
          // backgroundPositionY: '-15px',
          // backgroundPositionX: '900px',
          // backgroundPositionX: ''
          width: "100vw",
          height: "100vh",
        }}
      >
        <Overlay navOpen={navOpen} setNavOpen={setNavOpen} />
      </div>
      ) : (
        <>
          <div
            id="MainContainer"
            className="col-md-6"
            style={navOpen? unmountedStyle : mountedStyle}
          >
            <Section1 navOpen={navOpen} setNavOpen={setNavOpen} />
          </div>

          <div
            id="2ndContainer"
            className="col-md-6"
            style={{
              backgroundColor: "#0F0F0F",
              width: "100vw",
              height: "100vh",
            }}
          >
            <Section2 />
          </div>
          <div
            id="3rdContainer"
            style={{
              backgroundColor: "#0F0F0F",
              width: "100vw",
              height: "100vh",
            }}
          >
            <Section3 />
          </div>
          <div
            id="4rdContainer"
            style={{
              backgroundColor: "#0F0F0F",
              width: "100vw",
              height: "75vh",
            }}
          >
            <Section4 />
          </div>
          <div
            id="footer"
            style={{
              backgroundColor: "#0F0F0F",
              width: "100vw",
              height: "75vh",
            }}
          >
            <Footer />
          </div>
        </>
      )}
    </>
  );
};
