import { CodeFlow } from "./codeFlow";
import { Description } from "src/components/reusable/description";
import { ReactComponent as DemoNoBoundButton } from "./demoNoBound.svg";
import { Stack } from "@mui/system";

export const Section2: React.FC = () => {
  return (
    <>
      <Stack direction="column">
        <Description
          svgToShow={DemoNoBoundButton}
          showDemoButton={true}
          showHeaderText={false}
          header={"Implement Greatness"}
          body={
            `We start by writing code for you. From there our code makes it to 
            version control where it can be tested, tracked, and versioned by our team. 
            `
          }
        />
        <CodeFlow />
      </Stack>
    </>
  );
};
