import React, { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";

type descriptionType = {
  showDemoButton: boolean;
  showHeaderText: boolean;
  miniHeader?: string
  header: string;
  body: string;
  svgToShow?: React.ComponentType;
  sx?: Record<string, any>;
  additionalComponent?: React.ElementType;
};
export const Description: React.FC<descriptionType> = (
  props: descriptionType
) => {
  const SVG = props.svgToShow;
  const AdditionalComponent = props.additionalComponent;
  return (
    <Stack id="Description" direction="row" width="100%">
      <Stack
        direction="row"
        sx={{ width: "750px", height: "60px", ml: "80px", ...props.sx }}
      >
        <Stack direction="column">
          {props.showHeaderText ? (
            <Typography fontSize={14} align="left" sx={{}}>
              {props.miniHeader}
            </Typography>
          ) : (
            <></>
          )}
          <Typography variant="h4" fontSize={50} align="left">
            {props.header}
          </Typography>
          <Typography
            align="left"
            sx={{
              pt: "30px",
              lineHeight: "36px",
              fontSize: "24px",
              fontWeight: "400px",
            }}
          >
            {props.body}
          </Typography>
          <Stack sx={{ width: "152px", height: "18px", pt: "30px" }}>
            {props.showDemoButton && SVG ? <><SVG /></> : <> </>}
          </Stack>
        </Stack>
      </Stack>
      {AdditionalComponent ? (
        <Stack direction="row" sx={{ ml: "30%", mt: '15%' }}>
          <AdditionalComponent />
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};
