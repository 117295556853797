import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

export const Quote: React.FC = () => {
  return (
    <>
      <Stack
        direction="row"
        width="45vw"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: "45vh",
          pt: '5vh',
          ml: "27vw",
        }}
      >
        <Typography
          align="left"
          fontSize="36px"
          fontWeight="400px"
          fontFamily="Inter"
          sx={{ lineHeight: "46px" }}
        >
          “Lorem ipsum is placeholder text commonly used in the graphic, print,
          and publishing industries for previewing layouts and visual mockups.
          Lorem ipsum is placeholder text commonly used in the graphic, print,
          and publishing industries for previewing layouts and visual mockups."
        </Typography>
      </Stack>
      <Stack>
      <Typography
          align="left"
          fontFamily="Inter"
          fontWeight="500px"
          fontSize="20px"
          sx={{ lineHeight: "26px", ml: "20vw",  mt:'5vh', color: '#BCFF2D'}}
        >
          Eunice Guier
        </Typography>
        <Typography
          align="left"
          fontFamily="IBM Plex Sans"
          fontWeight="400px"
          fontSize="18px"
          sx={{ lineHeight: "30px", ml: "20vw", color: '#BCFF2D'}}
        >
          Client
        </Typography>
      </Stack>
    </>
  );
};
