
import React, { useState } from "react";
import { Footer } from "src/components/reusable/footer";
import { Header } from "src/components/reusable/header";
import { Overlay } from "src/components/reusable/overlay";
import { Section2 } from "src/sections/solutions/section2";
import { Section1 } from "src/sections/solutions/section1";
import { OverlayProps } from "src/types";

export const Solutions: React.FC<OverlayProps> = (props) => {
  const { navOpen, setNavOpen } = props;
  const mainContainerStyle = {
    backgroundColor: "#0F0F0F",
    width: "100vw",
    height: "100vh",
  };
  const mountedStyle = {
    ...mainContainerStyle,
    animation: "2500ms 1 forwards inAnimation",
  };
  const unmountedStyle = {
    ...mainContainerStyle,
    animation: "2500ms 1 backwards outAnimation",
  };
  return (
    <>
      {navOpen ? (
        <div
          id="MainContainer"
          style={{
            backgroundColor: "#0F0F0F",
            // backgroundImage: `url(${VectorTitlePage})`,
            // backgroundRepeat: "no-repeat",
            // backgroundAttachment: "fixed",
            // backgroundPosition: "right",
            // backgroundPositionY: '-15px',
            // backgroundPositionX: '900px',
            // backgroundPositionX: ''
            width: "100vw",
            height: "100vh",
          }}
        >
          <Overlay navOpen={navOpen} setNavOpen={setNavOpen} />
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundColor: "#0F0F0F",
              // backgroundImage: `url(${VectorTitlePage})`,
              // backgroundRepeat: "no-repeat",
              // backgroundAttachment: "fixed",
              // backgroundPosition: "right",
              // backgroundPositionY: '-15px',
              // backgroundPositionX: '900px',
              // backgroundPositionX: ''
              width: "100vw",
              height: "100vh",
            }}
          >
            <Header navOpen={navOpen} setNavOpen={setNavOpen} />
            <Section1 />
          </div>
          <div
            style={{
              backgroundColor: "#0F0F0F",
              // backgroundImage: `url(${VectorTitlePage})`,
              // backgroundRepeat: "no-repeat",
              // backgroundAttachment: "fixed",
              // backgroundPosition: "right",
              // backgroundPositionY: '-15px',
              // backgroundPositionX: '900px',
              // backgroundPositionX: ''
              width: "100vw",
              height: "100vh",
            }}
          >

            <Section2 />

          </div>
        </>
      )}
    </>
  );
};
