import { Stack } from "@mui/material"
import { Description } from "src/components/reusable/description"
import { TitleGroup } from "src/components/reusable/titleGroup"
import {ReactComponent as Button} from "src/resources/homeButton.svg"
export const Section1: React.FC = () => {
    return(
    <Stack>
        <TitleGroup header="Solutions" svg={Button}/>
        <Description body="We leverage open source tools for work." header="What Sets us Apart" showDemoButton={true} showHeaderText={false}/>
    </Stack>
        )
}